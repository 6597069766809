<template>
    <div class="confirmation-page" dir="rtl">
        <h3 class="text-center font-bold mb-4">عن مُختصر</h3>
        <div class="text-justify">
            <p>يسمح لك مُختصر بدمج التعلم مع حياتك اليومية بمساعدتك في اقتباس المعلومات من أفضل الكتب الواقعية في
                العالم،
                فيمكنك سماع أو قراءة معلومات حول أفضل الكتب المتخصصة في المال والأعمال والتسويق وتطوير الذات خلال اقل من
                ثلاثين دقيقة كل ذلك في موقع واحد. مع مُختصر ارتقِ بمعرفتك ووسِّع مداركك.</p>

            <p>سواء كنت تسعى إلى تحسين مهاراتك المهنية أو اكتشاف حُبّك لمواضيع جديدة، سيساعدك مُختصر في اكتشاف كتب
                ومؤلفين
                وإتاحة الأفكار الكبيرة للجميع مهما كانوا منشغلين في حياتهم.</p>

            <p>يختار فريقنا التحريري المكون من خبراء أجود كتب المال والأعمال والتسويق وتطوير الذات من ألمع مفكري العالم
                ويعيد صياغتها في مُختصرات معبرة ومؤثرة وتقديمها لك بشكل منظم وبسيط.</p>

            <h6 class="font-bold mt-5">ماذا عن حقوق النشر؟ هل مُختصر يسرق حقوق المؤلفين؟</h6>
            <p>في مُختصر نختار الأفكار الرئيسية وأهم المفاهيم من كل كتاب. فجميع كتاباتنا هي عبارة عن ابتكارات من فريق
                عملنا
                تمت صياغتها بطريقة مختلفة ومكتوبة بأسلوبنا الخاص ونقدمها بأصواتنا.</p>
            <p>مُختصراتنا مميزة وجذابة ولكن تم تركيزها على الأمور الضرورية نظرًا لحجمها، يمكنك تشبيهها بإعلان رائع لفيلم
                يعرض لقطات منه لكن باستبدال الفيلم بكتاب، فيمنح الكتاب الكامل تناولًا أعمق للموضوع، وأمثلة ومراجع
                وشروحات
                غنية لا يمكن إيجازها في مُختصر. أيضا بعض القراء يفضلون التعمق أكثر في فكرة معينه فيمكنهم شراء كامل
                الكتاب من
                المؤللف بدل الإكتفاء بـالمُختصرات.</p>
            <p>ونأمل في مُختصر أن نجلب للكاتبين مزيدًا من القراء وأن نذيع صيت مزيد من الكتب العظيمة وبذلك زيادة دخل
                الكاتبين، عن طريق منح الناس وسيلة سهلة لإكتشاف المعرفة. فنحن نؤمن بأهمية الأفكار المميزة وقدرتها على
                إسعاد
                العقل وتحسين صحته لذلك لمحات من موضوع جديد قد يوقظ فضولك حول تخصص دراسي كامل، وجملة رائعة واحدة قد توسع
                مداركك، وتجعلك متلهفًا للمزيد، لا راضيًا بالأقل.</p>
            <h6 class="font-bold mt-5">للتواصل: <a href="mailto:info@muktasar.com">info@muktasar.com</a></h6>

        </div>
    </div>
</template>
<script>
export default {
    data() {
    },
}
</script>
<style>
a {
    text-decoration: none !important;
}
</style>